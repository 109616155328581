import React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  gtagConversionKontaktLoad = () => {
    typeof window !== "undefined" &&
      window.gtag("event", "conversion", {
        send_to: "AW-1068261550/hKprCOe_sNcCEK7Bsf0D",
        value: 1.0,
        currency: "PLN",
      });
  };

  componentDidMount() {
    this.gtagConversionKontaktLoad();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    typeof window !== "undefined" &&
      window.gtag("event", "conversion", {
        send_to: "AW-1068261550/AlUNCO7bq8UCEK7Bsf0D",
        value: 1.0,
        currency: "PLN",
      });
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            Szybki kontakt i wycena wyłączników nożnych dla przemysłu i medycyny
          </title>
          <meta
            name="description"
            content="Dobierz optymalny wyłącznik nożny - zadzwoń do nas, wyślij email lub wypełnij formularz. Gwarantujemy szybką pomoc i wycenę."
          />
        </Helmet>

        <div
          className="full-width-image-container has-height-300 margin-top-0"
          style={{
            backgroundImage: `url('/img/hintergrund_gelb_2048.jpg')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-2 has-text-centered"
            style={{
              color: "white",
              padding: "1rem",
            }}
          >
            +48 22 843 08 20
            <br />
            Biuro handlowe i pomoc techniczna
          </h1>
        </div>

        <section className="section">
          <div className="container">
            <div className="content">
              <div class="columns">
                <div class="column">
                  <h4>Skontaktuj się z nami</h4>
                  <p className="">
                    {" "}
                    Szukasz konkretnego produktu? Chcesz zapytać o jego
                    dostępność?
                  </p>
                  <p className="">
                    Potrzebujesz konsultacji technicznej? Nasz dział techniczny
                    i handlowy są do Twojej dyspozycji!
                  </p>
                  <p className="">
                    Możesz do nas zadzwonić, napisać mail, wysłać zapytanie za
                    pośrednictwem formularza kontaktowego lub po prostu zostawić
                    numer telefonu – zadzwonimy w wygodnym dla Ciebie momencie i
                    chętnie odpowiemy na wszystkie pytania!
                  </p>
                </div>
                <div class="column">
                  <h4>
                    Biuro handlowe i pomoc techniczna:{" "}
                    <a
                      className="has-text-weight-semibold"
                      href="tel:+48228430820"
                    >
                      + 48 22 843 08 20
                    </a>
                  </h4>
                  <p>
                    Zostaw numer telefonu – oddzwonimy! Wystarczy podać
                    podstawowe dane kontaktowe oraz temat i proponowany termin
                    rozmowy.
                  </p>
                  <p>
                    Skorzystaj z formularza lub napisz na adres:
                    <a
                      className="has-text-weight-semibold"
                      subject="Zapytanie ze strony wylaczniki-nozne.pl"
                      href="mailto:info@steute.pl?subject=Zapytanie ze strony wylaczniki-nozne.pl"
                    >
                      {" "}
                      info@steute.pl
                    </a>
                  </p>
                </div>
              </div>
              <form
                name="contact"
                method="post"
                action="/kontakt/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"name"}>
                    Imię i Nazwisko
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"text"}
                      name={"name"}
                      onChange={this.handleChange}
                      id={"name"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"email"}>
                    Email
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"email"}
                      name={"email"}
                      onChange={this.handleChange}
                      id={"email"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"phone"}>
                    Numer telefonu
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"text"}
                      name={"phone"}
                      onChange={this.handleChange}
                      id={"phone"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"message"}>
                    Wiadomość
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      name={"message"}
                      onChange={this.handleChange}
                      id={"message"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <button className="button is-link" type="submit">
                    Wyślij
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
